<template>
    <div>
        <b-row>
            <b-col cols="12">
                <b-card class="my-card m-0">
                    <b-table 
                    striped
                    small
                    id="myTables"
                    bordered
                    selectable
                    selected-variant="primary"
                    select-mode="single"
                    responsive="sm"
                    :items="access"
                    :fields="fields"
                    head-row-variant="secondary"
                    >
                    <template #cell(checkbox)="row">
                        <b-form-checkbox
                        v-model="row.detailsShowing"
                        plain
                        class="vs-checkbox-con"
                        @change="row.toggleDetails"
                        >
                        </b-form-checkbox>
                    </template>
                    <template #cell(is_active)="data">
                        <span v-if="data.item.is_active">
                            Активен
                        </span>
                        <span v-else>
                            Не активен
                        </span>
                    </template>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 
                    </b-table>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { resizeable } from "@/utils/TableFunctions/resizeable.js";
export default {
    data(){
        return{
            id: this.$route.query.access_id,
            access: [],
            fields: [
            {key: 'checkbox', label: '', thStyle: {width: '30px'}},
            {key: 'id', label: 'ID', sortable: true, thStyle: {width: '150px'}},
            {key: 'name', label: 'Название', sortable: true, thStyle: {width: '150px'}},
            {key: 'route', label: 'Роут', sortable: true, thStyle: {width: '150px'}}
        ],
        }
    },
    methods:{
    },
    mounted(){
        this.$store.commit('pageData/setdataCount', null)
        resizeable()
        this.$http
            .get(`position/${this.id}/edit`)
            .then(res => {
                this.access = res.data.access_routes
                this.$store.commit('pageData/setdataCount', this.access.length)
            })
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/table.scss';
.my-card .card-body{
    padding: 0px;
}
.my-card{
overflow: auto; 
height: calc(100vh - 92px);
}
@media (max-height: 700px) {
  .my-card{
    overflow: auto; 
    height: 530px;
    }
}
</style> 